export const AppConfig = {
    AppTitle: "UPIDS App",
    fallbackLanguage: 'en',
    ContactEmail: 'info@secondthought.fi',
    ContactNumber: '+358 45 78327251',

    SERVER_URL: "https://prod.api.2nd.gdn/v3",
    b2cAppUrl: 'https://upids.io',
    externalApiUrl: ' https://europe-west3-second-gdn-v2-1.cloudfunctions.net',
    ioAPIUrl: 'https://prod.api.2nd.gdn/io',

    // SERVER_URL: "https://dev.api.2nd.gdn/v3",
    // b2cAppUrl: 'https://dev.upids.io',
    // externalApiUrl: 'https://europe-west3-upids-io-dev.cloudfunctions.net',
    // ioAPIUrl: 'https://dev.api.2nd.gdn/io',

}
